const HomeIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 54 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M8.68642 19.2237L8.68696 6.59217C8.68698 6.28762 8.80797 5.99554 9.02333 5.7802C9.2387 5.56486 9.53078 5.44388 9.83534 5.4439L14.4286 5.4441C14.7332 5.44411 15.0253 5.56511 15.2406 5.78047C15.4559 5.99583 15.5769 6.28791 15.5769 6.59247L15.5766 13.5869L8.68642 19.2237Z"
      fill="#3AACE9"
    />
    <path
      d="M27.0606 1.99987L7.53831 19.3513L7.53703 49.0803C7.537 49.6894 7.77894 50.2736 8.20963 50.7043C8.64032 51.135 9.22447 51.377 9.83358 51.377L44.2833 51.3785C44.8924 51.3785 45.4766 51.1366 45.9073 50.7059C46.338 50.2752 46.58 49.6911 46.58 49.082L46.5813 19.353L27.0606 1.99987Z"
      fill="#E3E3E3"
    />
    <path
      d="M52.3226 26.1155C52.0369 26.1156 51.7613 26.0092 51.5498 25.8169L27.0605 3.55104L2.56923 25.8148C2.3437 26.0198 2.04599 26.1268 1.74159 26.1122C1.43719 26.0977 1.15104 25.9628 0.946081 25.7373C0.741126 25.5117 0.634157 25.214 0.648707 24.9096C0.663256 24.6052 0.798132 24.3191 1.02366 24.1141L26.2878 1.14872C26.4992 0.956355 26.7748 0.849762 27.0606 0.849775C27.3465 0.849787 27.622 0.956404 27.8334 1.14878L53.0956 24.1163C53.2675 24.2722 53.3882 24.4765 53.4418 24.7023C53.4953 24.9281 53.4792 25.1648 53.3955 25.3813C53.3118 25.5978 53.1645 25.7838 52.973 25.9149C52.7815 26.0459 52.5547 26.1159 52.3226 26.1155Z"
      fill="#3AACE9"
    />
    <path
      d="M31.6523 36.4497L22.4657 36.4493C22.1612 36.4493 21.8691 36.5702 21.6537 36.7856C21.4383 37.0009 21.3173 37.293 21.3173 37.5975L21.3167 51.3774L32.8 51.3779L32.8006 37.598C32.8006 37.2935 32.6796 37.0014 32.4643 36.786C32.2489 36.5707 31.9569 36.4497 31.6523 36.4497Z"
      fill="#2D9CD8"
    />
    <path
      d="M31.6524 29.5596L22.4658 29.5592C22.1612 29.5592 21.8692 29.4382 21.6538 29.2228C21.4385 29.0075 21.3175 28.7154 21.3175 28.4108L21.3178 21.5209C21.3178 21.2163 21.4388 20.9243 21.6542 20.7089C21.8696 20.4936 22.1616 20.3726 22.4662 20.3726L31.6528 20.373C31.9573 20.373 32.2494 20.494 32.4648 20.7094C32.6801 20.9247 32.8011 21.2168 32.8011 21.5214L32.8008 28.4113C32.8007 28.7159 32.6798 29.008 32.4644 29.2233C32.249 29.4386 31.9569 29.5596 31.6524 29.5596Z"
      fill="#3AACE9"
    />
  </svg>
);
export default HomeIcon;
