const EatIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10.6294 51.333L11.6666 23.333H16.3332L17.3704 51.333C17.3877 51.7864 17.3133 52.2385 17.1516 52.6625C16.99 53.0864 16.7445 53.4733 16.4298 53.8001C16.1151 54.1269 15.7377 54.3868 15.3202 54.5643C14.9026 54.7418 14.4536 54.8332 13.9999 54.833C13.5462 54.8332 13.0972 54.7418 12.6796 54.5643C12.2621 54.3868 11.8847 54.1269 11.57 53.8001C11.2553 53.4733 11.0098 53.0864 10.8482 52.6625C10.6865 52.2385 10.6121 51.7864 10.6294 51.333Z"
      fill="#5B5B5B"
    />
    <path
      d="M38.6294 51.333L39.6666 23.333H44.3332L45.3704 51.333C45.3877 51.7864 45.3133 52.2385 45.1516 52.6625C44.99 53.0864 44.7445 53.4733 44.4298 53.8001C44.1151 54.1269 43.7377 54.3868 43.3202 54.5643C42.9026 54.7418 42.4536 54.8332 41.9999 54.833C41.5462 54.8332 41.0972 54.7418 40.6796 54.5643C40.2621 54.3868 39.8847 54.1269 39.57 53.8001C39.2553 53.4733 39.0098 53.0864 38.8482 52.6625C38.6865 52.2385 38.6121 51.7864 38.6294 51.333Z"
      fill="#5B5B5B"
    />
    <path
      d="M14 26.8332C11.2162 26.8301 8.54725 25.7228 6.57879 23.7544C4.61033 21.7859 3.50309 19.117 3.5 16.3332V11.6665C3.5 8.88173 4.60625 6.21101 6.57538 4.24188C8.54451 2.27275 11.2152 1.1665 14 1.1665C16.7848 1.1665 19.4555 2.27275 21.4246 4.24188C23.3938 6.21101 24.5 8.88173 24.5 11.6665V16.3332C24.4969 19.117 23.3897 21.7859 21.4212 23.7544C19.4527 25.7228 16.7838 26.8301 14 26.8332Z"
      fill="#C6C6C6"
    />
    <path
      d="M48.9193 3.45534L47.8332 18.6663H44.3332L43.247 3.45534C43.2252 3.15058 43.0887 2.86539 42.8651 2.65716C42.6415 2.44893 42.3474 2.33311 42.0418 2.33301H41.9578C41.6523 2.33311 41.3581 2.44893 41.1345 2.65716C40.9109 2.86539 40.7745 3.15058 40.7527 3.45534L39.6665 18.6663H36.1665L35.0803 3.45534C35.0585 3.15058 34.9221 2.86539 34.6985 2.65716C34.4749 2.44893 34.1807 2.33311 33.8752 2.33301C33.7164 2.33301 33.5593 2.36427 33.4126 2.42501C33.266 2.48575 33.1328 2.57478 33.0205 2.68702C32.9083 2.79925 32.8192 2.9325 32.7585 3.07914C32.6978 3.22578 32.6665 3.38295 32.6665 3.54167V20.9997C32.6665 21.7657 32.8174 22.5243 33.1105 23.232C33.4037 23.9397 33.8334 24.5828 34.375 25.1245C35.469 26.2184 36.9527 26.833 38.4998 26.833H45.4998C46.2659 26.833 47.0244 26.6821 47.7322 26.389C48.4399 26.0958 49.083 25.6661 49.6246 25.1245C50.1663 24.5828 50.596 23.9397 50.8891 23.232C51.1823 22.5243 51.3332 21.7657 51.3332 20.9997V3.54167C51.3332 3.22112 51.2058 2.91369 50.9792 2.68702C50.7525 2.46035 50.4451 2.33301 50.1245 2.33301C49.819 2.33311 49.5248 2.44893 49.3012 2.65716C49.0776 2.86539 48.9412 3.15058 48.9193 3.45534Z"
      fill="#C6C6C6"
    />
  </svg>
);
export default EatIcon;
