const KidsIcon = () => (
  <svg
    width="50"
    height="56"
    viewBox="0 0 50 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4326 7.42483C23.5215 7.42483 25.2149 5.73146 25.2149 3.64259C25.2149 1.55372 23.5215 -0.139648 21.4326 -0.139648C19.3438 -0.139648 17.6504 1.55372 17.6504 3.64259C17.6504 5.73146 19.3438 7.42483 21.4326 7.42483Z"
      fill="#D95B53"
    />
    <path
      d="M46.4871 24.9973H46.6478C46.6478 24.9973 46.5762 24.9733 46.4717 24.9395C46.4754 24.959 46.4835 24.9777 46.4871 24.9973Z"
      fill="#3FAA7C"
    />
    <path
      d="M22.6934 55.3331H20.1719C9.03128 55.3331 0 46.3018 0 35.1612L0 25.0752H42.8654V35.1612C42.8654 46.3018 33.8341 55.3331 22.6934 55.3331Z"
      fill="#EEBC99"
    />
    <path
      d="M42.8654 25.0753H0L0 23.8145C0 11.9776 9.59573 2.38184 21.4327 2.38184C33.2696 2.38184 42.8654 11.9776 42.8654 23.8145V25.0753Z"
      fill="#D95B53"
    />
    <path
      d="M47.4737 33.9004H19.5599C16.658 33.9004 13.8081 33.13 11.3016 31.6678L0 25.0752H42.8654L48.9197 29.3132C50.9394 30.727 49.939 33.9004 47.4737 33.9004Z"
      fill="#AE453E"
    />
    <path
      d="M32.7794 37.6826C32.7794 36.9864 32.2149 36.4219 31.5186 36.4219H11.3467C10.6504 36.4219 10.0859 36.9864 10.0859 37.6826C10.0859 38.0515 10.0859 38.4936 10.0859 38.9434H32.7794C32.7794 38.4936 32.7794 38.0515 32.7794 37.6826Z"
      fill="white"
    />
    <path
      d="M10.1602 38.9434C10.7899 44.6079 15.6027 49.0293 21.4325 49.0293C27.2622 49.0293 32.075 44.6079 32.7048 38.9434H10.1602Z"
      fill="#AE453E"
    />
    <path
      d="M15.2725 47.1977C17.0486 48.3517 19.1617 49.0293 21.433 49.0293C23.7043 49.0293 25.8174 48.3517 27.5935 47.1977C26.2256 45.2608 23.985 43.9863 21.433 43.9863C18.8809 43.9863 16.6403 45.2608 15.2725 47.1977Z"
      fill="#FA645A"
    />
  </svg>
);
export default KidsIcon;
