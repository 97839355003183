const CommunityIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.0002 54.8333C40.2425 54.8333 50.1668 51.177 50.1668 46.6667C50.1668 42.1563 40.2425 38.5 28.0002 38.5C15.7579 38.5 5.8335 42.1563 5.8335 46.6667C5.8335 51.177 15.7579 54.8333 28.0002 54.8333Z"
      fill="#ED7064"
    />
    <path
      d="M28 1.1665C23.3587 1.1665 18.9075 3.01025 15.6256 6.29214C12.3437 9.57402 10.5 14.0252 10.5 18.6665C10.5 29.107 23.751 42.5832 27.1915 45.9012C27.408 46.1117 27.698 46.2295 28 46.2295C28.302 46.2295 28.592 46.1117 28.8085 45.9012C32.249 42.5832 45.5 29.107 45.5 18.6665C45.5 14.0252 43.6563 9.57402 40.3744 6.29214C37.0925 3.01025 32.6413 1.1665 28 1.1665Z"
      fill="#EAD8C5"
    />
    <path
      d="M37.3334 18.0832C37.3383 16.9948 37.0035 15.932 36.3757 15.043C35.7479 14.1539 34.8583 13.4828 33.831 13.1232C32.8037 12.7637 31.6899 12.7336 30.6446 13.0371C29.5994 13.3406 28.6749 13.9627 28 14.8166C27.5525 14.2517 26.9932 13.7853 26.357 13.4467C25.7209 13.1081 25.0217 12.9044 24.3032 12.8486C23.5847 12.7928 22.8624 12.8859 22.1816 13.1222C21.5008 13.3585 20.8761 13.7329 20.3467 14.2219C19.8173 14.7108 19.3946 15.3039 19.1051 15.9638C18.8155 16.6237 18.6654 17.3363 18.6641 18.057C18.6628 18.7776 18.8103 19.4908 19.0974 20.1518C19.3845 20.8128 19.8051 21.4073 20.3327 21.8982L28 29.4991L35.6674 21.9017C36.1922 21.4136 36.6109 20.8227 36.8976 20.1658C37.1842 19.5089 37.3325 18.8 37.3334 18.0832Z"
      fill="#ED7064"
    />
  </svg>
);
export default CommunityIcon;
