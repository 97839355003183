const TipsIcon = () => (
  <svg
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.22589 47.9231C8.05084 47.7469 7.93369 47.5216 7.89008 47.2771C7.84646 47.0326 7.87845 46.7807 7.98179 46.5549L16.3777 28.0738L7.9769 9.59145C7.8742 9.3653 7.84294 9.11319 7.8873 8.8688C7.93166 8.62441 8.04953 8.39937 8.22516 8.22374C8.40079 8.0481 8.62583 7.93024 8.87022 7.88588C9.11461 7.84152 9.36672 7.87278 9.59287 7.97548L28.0752 16.3763L46.5563 7.97548C46.7826 7.87215 47.0351 7.84045 47.28 7.88464C47.5248 7.92883 47.7503 8.04679 47.9263 8.22272C48.1022 8.39865 48.2201 8.62414 48.2643 8.86899C48.3085 9.11384 48.2768 9.36634 48.1735 9.59267L39.7727 28.0738L48.1735 46.5561C48.2762 46.7823 48.3074 47.0344 48.2631 47.2788C48.2187 47.5231 48.1009 47.7482 47.9252 47.9238C47.7496 48.0994 47.5246 48.2173 47.2802 48.2617C47.0358 48.306 46.7837 48.2748 46.5575 48.1721L28.0752 39.7712L9.59409 48.1721C9.36781 48.2748 9.11556 48.306 8.87106 48.2615C8.62657 48.217 8.40148 48.0989 8.22589 47.9231Z"
      fill="#BFDEE0"
    />
    <path
      d="M28.0752 56.1459C27.8266 56.1461 27.5839 56.0703 27.3795 55.9288C27.1751 55.7872 27.0188 55.5866 26.9316 55.3538L19.805 36.3454L0.794156 29.2176C0.560981 29.1307 0.35995 28.9745 0.218009 28.7701C0.0760684 28.5657 0 28.3228 0 28.074C0 27.8251 0.0760684 27.5822 0.218009 27.3778C0.35995 27.1734 0.560981 27.0173 0.794156 26.9304L19.805 19.8025L26.9316 0.794156C27.0185 0.560981 27.1746 0.359951 27.379 0.21801C27.5834 0.0760685 27.8264 0 28.0752 0C28.3241 0 28.567 0.0760685 28.7714 0.21801C28.9758 0.359951 29.1319 0.560981 29.2188 0.794156L36.3454 19.8025L55.3563 26.9304C55.5894 27.0173 55.7905 27.1734 55.9324 27.3778C56.0743 27.5822 56.1504 27.8251 56.1504 28.074C56.1504 28.3228 56.0743 28.5657 55.9324 28.7701C55.7905 28.9745 55.5894 29.1307 55.3563 29.2176L36.3454 36.3454L29.2188 55.3538C29.1316 55.5866 28.9753 55.7872 28.7709 55.9288C28.5665 56.0703 28.3238 56.1461 28.0752 56.1459Z"
      fill="#5A7A84"
    />
    <path
      d="M28.0754 32.956C30.7717 32.956 32.9575 30.7703 32.9575 28.074C32.9575 25.3777 30.7717 23.1919 28.0754 23.1919C25.3791 23.1919 23.1934 25.3777 23.1934 28.074C23.1934 30.7703 25.3791 32.956 28.0754 32.956Z"
      fill="white"
    />
  </svg>
);
export default TipsIcon;
