const TravelPlanIcon = () => (
  <svg
    width="55"
    height="56"
    viewBox="0 0 55 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.35601 4.97803H48.6671C52.1018 4.97803 54.8893 7.76558 54.8893 11.2002V21.1558H0.133789V11.2002C0.133789 7.76558 2.92134 4.97803 6.35601 4.97803Z"
      fill="url(#paint0_linear_1127_1938)"
    />
    <path
      d="M0.133789 21.1558H54.8893V49.778C54.8893 53.2127 52.1018 56.0002 48.6671 56.0002H6.35601C2.92134 56.0002 0.133789 53.2127 0.133789 49.778V21.1558Z"
      fill="url(#paint1_linear_1127_1938)"
    />
    <path
      d="M13.8226 11.2C13.1356 11.2 12.5781 10.6425 12.5781 9.95555V1.24444C12.5781 0.557511 13.1356 0 13.8226 0C14.5095 0 15.067 0.557511 15.067 1.24444V9.95555C15.067 10.6425 14.5095 11.2 13.8226 11.2Z"
      fill="url(#paint2_linear_1127_1938)"
    />
    <path
      d="M41.2 11.2C40.5131 11.2 39.9556 10.6425 39.9556 9.95555V1.24444C39.9556 0.557511 40.5131 0 41.2 0C41.8869 0 42.4445 0.557511 42.4445 1.24444V9.95555C42.4445 10.6425 41.8869 11.2 41.2 11.2Z"
      fill="url(#paint3_linear_1127_1938)"
    />
    <path
      d="M18.8 38.5776H11.3333C10.646 38.5776 10.0889 39.1348 10.0889 39.8221V47.2887C10.0889 47.9761 10.646 48.5332 11.3333 48.5332H18.8C19.4873 48.5332 20.0444 47.9761 20.0444 47.2887V39.8221C20.0444 39.1348 19.4873 38.5776 18.8 38.5776Z"
      fill="url(#paint4_linear_1127_1938)"
    />
    <path
      d="M18.8 26.1333H11.3333C10.646 26.1333 10.0889 26.6904 10.0889 27.3777V34.8444C10.0889 35.5317 10.646 36.0889 11.3333 36.0889H18.8C19.4873 36.0889 20.0444 35.5317 20.0444 34.8444V27.3777C20.0444 26.6904 19.4873 26.1333 18.8 26.1333Z"
      fill="url(#paint5_linear_1127_1938)"
    />
    <path
      d="M31.2448 38.5776H23.7781C23.0908 38.5776 22.5337 39.1348 22.5337 39.8221V47.2887C22.5337 47.9761 23.0908 48.5332 23.7781 48.5332H31.2448C31.9321 48.5332 32.4892 47.9761 32.4892 47.2887V39.8221C32.4892 39.1348 31.9321 38.5776 31.2448 38.5776Z"
      fill="url(#paint6_linear_1127_1938)"
    />
    <path
      d="M31.2448 26.1333H23.7781C23.0908 26.1333 22.5337 26.6904 22.5337 27.3777V34.8444C22.5337 35.5317 23.0908 36.0889 23.7781 36.0889H31.2448C31.9321 36.0889 32.4892 35.5317 32.4892 34.8444V27.3777C32.4892 26.6904 31.9321 26.1333 31.2448 26.1333Z"
      fill="url(#paint7_linear_1127_1938)"
    />
    <path
      d="M43.6891 26.1333H36.2225C35.5352 26.1333 34.978 26.6904 34.978 27.3777V34.8444C34.978 35.5317 35.5352 36.0889 36.2225 36.0889H43.6891C44.3764 36.0889 44.9336 35.5317 44.9336 34.8444V27.3777C44.9336 26.6904 44.3764 26.1333 43.6891 26.1333Z"
      fill="url(#paint8_linear_1127_1938)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1127_1938"
        x1="27.5116"
        y1="4.97803"
        x2="27.5116"
        y2="21.1558"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5B5E71" />
        <stop offset="1" stopColor="#393A46" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1127_1938"
        x1="27.5116"
        y1="21.1558"
        x2="27.5116"
        y2="56.0002"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E0E0E6" />
        <stop offset="1" stopColor="#C2C3CD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1127_1938"
        x1="13.8226"
        y1="0"
        x2="13.8226"
        y2="11.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1127_1938"
        x1="41.2"
        y1="0"
        x2="41.2"
        y2="11.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1127_1938"
        x1="15.0666"
        y1="38.5776"
        x2="15.0666"
        y2="48.5332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_1127_1938"
        x1="15.0666"
        y1="26.1333"
        x2="15.0666"
        y2="36.0889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_1127_1938"
        x1="27.5115"
        y1="38.5776"
        x2="27.5115"
        y2="48.5332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-Color="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_1127_1938"
        x1="27.5115"
        y1="26.1333"
        x2="27.5115"
        y2="36.0889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1127_1938"
        x1="39.9558"
        y1="26.1333"
        x2="39.9558"
        y2="36.0889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A2A3B4" />
        <stop offset="1" stopColor="#83849B" />
      </linearGradient>
    </defs>
  </svg>
);
export default TravelPlanIcon;
