const WeatherIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <g clipPath="url(#clip0_1127_494)">
      <path
        d="M54.7825 24.3479H52.3478C51.6749 24.3479 51.1304 23.8028 51.1304 23.1305C51.1304 22.4582 51.6749 21.9131 52.3478 21.9131H54.7825C55.4554 21.9131 55.9999 22.4582 55.9999 23.1305C55.9999 23.8028 55.4554 24.3479 54.7825 24.3479Z"
        fill="#EFD358"
      />
      <path
        d="M46.6427 10.5748C46.3312 10.5748 46.0197 10.4559 45.782 10.2182C45.3064 9.74321 45.3064 8.97223 45.782 8.49669L47.5034 6.77463C47.979 6.29908 48.7493 6.29908 49.2249 6.77463C49.7004 7.24958 49.7004 8.02055 49.2249 8.4961L47.5034 10.2182C47.2656 10.4559 46.9542 10.5748 46.6427 10.5748Z"
        fill="#EFD358"
      />
      <path
        d="M32.8697 4.86957C32.1968 4.86957 31.6523 4.32448 31.6523 3.65218V1.21739C31.6523 0.545092 32.1968 0 32.8697 0C33.5426 0 34.0871 0.545092 34.0871 1.21739V3.65218C34.0871 4.32448 33.5426 4.86957 32.8697 4.86957Z"
        fill="#EFD358"
      />
      <path
        d="M19.0966 10.5748C18.7851 10.5748 18.4736 10.4559 18.2358 10.2182L16.5144 8.4961C16.0388 8.02055 16.0388 7.24958 16.5144 6.77463C16.9899 6.29908 17.7603 6.29908 18.2358 6.77463L19.9573 8.49669C20.4329 8.97223 20.4329 9.74321 19.9573 10.2182C19.7195 10.4559 19.4081 10.5748 19.0966 10.5748Z"
        fill="#EFD358"
      />
      <path
        d="M32.8693 36.5219C40.2652 36.5219 46.2607 30.5264 46.2607 23.1306C46.2607 15.7348 40.2652 9.73926 32.8693 9.73926C25.4735 9.73926 19.478 15.7348 19.478 23.1306C19.478 30.5264 25.4735 36.5219 32.8693 36.5219Z"
        fill="#EFD358"
      />
      <path
        d="M42.3797 55.9998H18.5769C10.1717 55.9998 2.54119 50.4596 0.553901 42.2927C-2.3735 30.2623 6.70727 19.478 18.2609 19.478C25.9457 19.478 32.7471 24.2994 35.3698 31.3999C37.5394 29.9828 40.0539 29.2172 42.6087 29.2172C51.0681 29.2172 57.7206 37.1016 55.6035 45.9079C54.1595 51.9145 48.5575 55.9998 42.3797 55.9998Z"
        fill="#E0E0E0"
      />
    </g>
    <defs>
      <clipPath id="clip0_1127_494">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default WeatherIcon;
