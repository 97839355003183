const ToDoIcon = () => (
  <svg
    width="50"
    height="54"
    viewBox="0 0 50 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6665 52.6665L15.6665 1.33317C15.6665 1.02375 15.7894 0.727005 16.0082 0.508213C16.227 0.28942 16.5238 0.166504 16.8332 0.166504L28.4998 0.166504C28.8093 0.166504 29.106 0.28942 29.3248 0.508213C29.5436 0.727005 29.6665 1.02375 29.6665 1.33317V52.6665C29.6665 52.9759 29.5436 53.2727 29.3248 53.4915C29.106 53.7103 28.8093 53.8332 28.4998 53.8332H16.8332C16.5238 53.8332 16.227 53.7103 16.0082 53.4915C15.7894 53.2727 15.6665 52.9759 15.6665 52.6665Z"
      fill="#BF8C5A"
    />
    <path
      d="M22.6667 42.1665C22.9761 42.1665 23.2728 42.2894 23.4916 42.5082C23.7104 42.727 23.8333 43.0238 23.8333 43.3332V53.8332H21.5V43.3332C21.5 43.0238 21.6229 42.727 21.8417 42.5082C22.0605 42.2894 22.3572 42.1665 22.6667 42.1665Z"
      fill="#A2703F"
    />
    <path
      d="M0.5 25.833L0.5 9.49967C0.5 9.19026 0.622916 8.89351 0.841709 8.67472C1.0605 8.45592 1.35725 8.33301 1.66667 8.33301L41.3333 8.33301C41.5016 8.33317 41.6679 8.36974 41.8208 8.44022C41.9736 8.51071 42.1094 8.61343 42.2188 8.74134L49.2188 16.908C49.3996 17.1193 49.4989 17.3883 49.4989 17.6663C49.4989 17.9444 49.3996 18.2134 49.2188 18.4247L42.2188 26.5913C42.1094 26.7192 41.9736 26.822 41.8208 26.8925C41.6679 26.9629 41.5016 26.9995 41.3333 26.9997H1.66667C1.35725 26.9997 1.0605 26.8768 0.841709 26.658C0.622916 26.4392 0.5 26.1424 0.5 25.833Z"
      fill="#5A7A84"
    />
    <path d="M29.6665 27H15.6665V29.3333H29.6665V27Z" fill="#A2703F" />
  </svg>
);
export default ToDoIcon;
