const ShoppingIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.3333 4.6665H4.66667C4.02267 4.6665 3.5 5.18917 3.5 5.83317V51.3332C3.5 53.2663 5.06683 54.8332 7 54.8332H49C50.9332 54.8332 52.5 53.2663 52.5 51.3332V5.83317C52.5 5.18917 51.9773 4.6665 51.3333 4.6665Z"
      fill="#E86C60"
    />
    <path
      d="M27.9998 33.8335C20.924 33.8335 15.1665 28.076 15.1665 21.0002C15.1665 20.3562 15.688 19.8335 16.3332 19.8335C16.9783 19.8335 17.4998 20.3562 17.4998 21.0002C17.4998 26.7892 22.2097 31.5002 27.9998 31.5002C33.79 31.5002 38.4998 26.7892 38.4998 21.0002C38.4998 20.3562 39.0213 19.8335 39.6665 19.8335C40.3117 19.8335 40.8332 20.3562 40.8332 21.0002C40.8332 28.076 35.0757 33.8335 27.9998 33.8335Z"
      fill="#E6E6E6"
    />
    <path
      d="M47.8333 10.4998H8.16667C5.5895 10.4998 3.5 8.41034 3.5 5.83317C3.5 3.256 5.5895 1.1665 8.16667 1.1665H47.8333C50.4105 1.1665 52.5 3.256 52.5 5.83317C52.5 8.41034 50.4105 10.4998 47.8333 10.4998Z"
      fill="#C94F49"
    />
  </svg>
);
export default ShoppingIcon;
