export const ALL = 'ALL';

export const SECTIONS = {
  BANNER: 'Banner',
  SUB_CATEGORY_PIILS: 'Sub-Category Pills',
  TOP_CATEGORY: 'Top Category',
  POPULAR_SUB_CATEGORY: 'Popular Sub-Category',
  ALL_SUB_CATEGORY: 'All Sub-Category',
  BROWSE_BY_CATEGORIES: 'Browse by Categories',
};

export const PRICING = 'Pricing';

export const SORTING_ORDER = {
  POPULARITY: 'Popularity',
  LOW_TO_HIGH: 'Low to high',
  HIGH_TO_LOW: 'High to low',
};
