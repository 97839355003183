const TransportationIcon = () => (
  <svg
    width="56"
    height="51"
    viewBox="0 0 56 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7329 50.9996H5.52169C5.20293 50.9996 4.89724 50.873 4.67184 50.6476C4.44645 50.4222 4.31982 50.1165 4.31982 49.7977V40.1828C4.31982 39.8641 4.44645 39.5584 4.67184 39.333C4.89724 39.1076 5.20293 38.981 5.52169 38.981H12.7329C13.0516 38.981 13.3573 39.1076 13.5827 39.333C13.8081 39.5584 13.9347 39.8641 13.9347 40.1828V49.7977C13.9347 50.1165 13.8081 50.4222 13.5827 50.6476C13.3573 50.873 13.0516 50.9996 12.7329 50.9996Z"
      fill="#363636"
    />
    <path
      d="M51.1928 50.9996H43.9816C43.6629 50.9996 43.3572 50.873 43.1318 50.6476C42.9064 50.4222 42.7798 50.1165 42.7798 49.7977V40.1828C42.7798 39.8641 42.9064 39.5584 43.1318 39.333C43.3572 39.1076 43.6629 38.981 43.9816 38.981H51.1928C51.5116 38.981 51.8173 39.1076 52.0427 39.333C52.2681 39.5584 52.3947 39.8641 52.3947 40.1828V49.7977C52.3947 50.1165 52.2681 50.4222 52.0427 50.6476C51.8173 50.873 51.5116 50.9996 51.1928 50.9996Z"
      fill="#363636"
    />
    <path
      d="M49.9908 22.1548L48.0858 5.96933C47.9483 4.7997 47.3863 3.72122 46.5063 2.93851C45.6263 2.15581 44.4897 1.72333 43.312 1.72314H13.4024C12.2247 1.72333 11.0881 2.15581 10.2081 2.93851C9.32813 3.72122 8.76608 4.7997 8.62859 5.96933L6.72363 22.1548H49.9908Z"
      fill="#E3E3E3"
    />
    <path
      d="M49.9906 23.3569C49.6965 23.3566 49.4126 23.2484 49.1929 23.0528C48.9732 22.8572 48.8328 22.5878 48.7984 22.2957L46.8922 6.10895C46.7889 5.23168 46.3671 4.42287 45.7069 3.836C45.0467 3.24913 44.194 2.92504 43.3107 2.92521H13.4035C12.5201 2.92504 11.6675 3.24913 11.0073 3.836C10.3471 4.42287 9.92528 5.23168 9.82193 6.10895L7.91697 22.2957C7.875 22.6082 7.71184 22.8918 7.4627 23.0852C7.21355 23.2785 6.89838 23.3662 6.58516 23.3293C6.27194 23.2924 5.98578 23.1339 5.7884 22.8879C5.59102 22.6419 5.49824 22.3282 5.53007 22.0144L7.43502 5.82772C7.60726 4.36574 8.31013 3.01783 9.4103 2.03973C10.5105 1.06163 11.9314 0.521384 13.4035 0.521484H43.3107C44.7828 0.521384 46.2037 1.06163 47.3039 2.03973C48.404 3.01783 49.1069 4.36574 49.2791 5.82772L51.1841 22.0144C51.2213 22.3309 51.1314 22.6493 50.9339 22.8995C50.7365 23.1496 50.4478 23.3112 50.1313 23.3485C50.0846 23.3542 50.0376 23.357 49.9906 23.3569Z"
      fill="#38A838"
    />
    <path
      d="M54.7983 42.5867H1.91622C1.59747 42.5867 1.29177 42.4601 1.06637 42.2347C0.84098 42.0093 0.714355 41.7036 0.714355 41.3848V28.1643C0.714355 26.2518 1.4741 24.4176 2.82646 23.0652C4.17882 21.7129 6.01302 20.9531 7.92554 20.9531H48.7889C50.7015 20.9531 52.5357 21.7129 53.888 23.0652C55.2404 24.4176 56.0001 26.2518 56.0001 28.1643V41.3848C56.0001 41.7036 55.8735 42.0093 55.6481 42.2347C55.4227 42.4601 55.117 42.5867 54.7983 42.5867Z"
      fill="#78D478"
    />
    <path
      d="M47.587 32.9719C49.5784 32.9719 51.1926 31.3577 51.1926 29.3663C51.1926 27.375 49.5784 25.7607 47.587 25.7607C45.5957 25.7607 43.9814 27.375 43.9814 29.3663C43.9814 31.3577 45.5957 32.9719 47.587 32.9719Z"
      fill="white"
    />
    <path
      d="M9.12708 32.9719C11.1184 32.9719 12.7327 31.3577 12.7327 29.3663C12.7327 27.375 11.1184 25.7607 9.12708 25.7607C7.13576 25.7607 5.52148 27.375 5.52148 29.3663C5.52148 31.3577 7.13576 32.9719 9.12708 32.9719Z"
      fill="white"
    />
    <path
      d="M36.7704 34.1737H19.9443C19.3067 34.1737 18.6954 33.9204 18.2446 33.4696C17.7938 33.0188 17.5405 32.4074 17.5405 31.7699C17.5405 31.1324 17.7938 30.521 18.2446 30.0702C18.6954 29.6195 19.3067 29.3662 19.9443 29.3662H36.7704C37.4079 29.3662 38.0193 29.6195 38.4701 30.0702C38.9208 30.521 39.1741 31.1324 39.1741 31.7699C39.1741 32.4074 38.9208 33.0188 38.4701 33.4696C38.0193 33.9204 37.4079 34.1737 36.7704 34.1737Z"
      fill="#337133"
    />
    <path
      d="M0.714355 37.7793H56.0001V41.3849C56.0001 41.7036 55.8735 42.0093 55.6481 42.2347C55.4227 42.4601 55.117 42.5868 54.7983 42.5868H1.91622C1.59747 42.5868 1.29177 42.4601 1.06637 42.2347C0.84098 42.0093 0.714355 41.7036 0.714355 41.3849V37.7793Z"
      fill="#49C549"
    />
    <path
      d="M9.12736 40.1829C10.4549 40.1829 11.5311 39.1068 11.5311 37.7792C11.5311 36.4517 10.4549 35.3755 9.12736 35.3755C7.79982 35.3755 6.72363 36.4517 6.72363 37.7792C6.72363 39.1068 7.79982 40.1829 9.12736 40.1829Z"
      fill="#F7BF26"
    />
    <path
      d="M47.5868 40.1829C48.9144 40.1829 49.9906 39.1068 49.9906 37.7792C49.9906 36.4517 48.9144 35.3755 47.5868 35.3755C46.2593 35.3755 45.1831 36.4517 45.1831 37.7792C45.1831 39.1068 46.2593 40.1829 47.5868 40.1829Z"
      fill="#F7BF26"
    />
  </svg>
);
export default TransportationIcon;
