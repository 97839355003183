const InfoIconLarge = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M28 0C12.5355 0 0 12.5355 0 28C0 43.4645 12.5355 56 28 56C43.4645 56 56 43.4645 56 28C56 12.5355 43.4645 0 28 0Z"
      fill="url(#paint0_linear_1127_6297)"
    />
    <path
      d="M30.2249 22.5254H26.3106C25.2297 22.5254 24.3535 23.0295 24.3535 23.6513V43.9177C24.3535 44.5395 25.2297 45.0436 26.3106 45.0436H30.2249C31.3058 45.0436 32.182 44.5395 32.182 43.9177V23.6513C32.182 23.0295 31.3058 22.5254 30.2249 22.5254Z"
      fill="url(#paint1_linear_1127_6297)"
    />
    <path
      d="M27.7788 19.3427C30.1019 19.3427 31.9852 17.4593 31.9852 15.1362C31.9852 12.813 30.1019 10.9297 27.7788 10.9297C25.4556 10.9297 23.5723 12.813 23.5723 15.1362C23.5723 17.4593 25.4556 19.3427 27.7788 19.3427Z"
      fill="url(#paint2_linear_1127_6297)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1127_6297"
        x1="28"
        y1="0"
        x2="28"
        y2="56"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DEF2FD" />
        <stop offset="1" stopColor="#9ED9FA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1127_6297"
        x1="28.2678"
        y1="22.5254"
        x2="28.2678"
        y2="45.0436"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#60BEF6" />
        <stop offset="1" stopColor="#29A0EA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1127_6297"
        x1="27.7788"
        y1="10.9297"
        x2="27.7788"
        y2="19.3427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#60BEF6" />
        <stop offset="1" stopColor="#29A0EA" />
      </linearGradient>
    </defs>
  </svg>
);
export default InfoIconLarge;
